import {
  Layout as RaLayout,
  AppBar as RaAppBar,
  useDataProvider,
  useGetOne,
  useRecordContext,
} from "react-admin";

import Menu from "./Menu";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Breadcrumbs, Link } from "@mui/material";
import { HomeRounded } from "@mui/icons-material";
import {
  Link as RouterLink,
  Route,
  Routes,
  MemoryRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";

import logo from "./assets/logo_simple.png";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: "bold",
    paddingTop: 2,
  },
  bar: {
    backgroundColor: "black",
    height: 50,
  },
  bread: {},
  spacer: {
    flex: 1,
  },
  logo: {
    width: "auto",
    height: 13,
    backgroundColor: "transparent",
    margin: 7,
  },
});

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const AppBar = (props) => {
  const classes = useStyles();
  return (
    <RaAppBar sx={{ backgroundColor: "black" }} className={classes.bar}>
      <img alt="logo" className={classes.logo} src={logo} />
      <Typography variant="h6" color="inherit" className={classes.title}>
        CMS
      </Typography>
      <span className={classes.spacer} />
    </RaAppBar>
  );
};

const Layout = ({ children }) => {
  const location = useLocation();

  const pathnames = location && location.pathname.split("/").filter((x) => x);

  const resource = pathnames[0];
  const id = pathnames[1];

  const [locations, setLocations] = useState([]);

  const classes = useStyles();

  console.log("enabled", resource && id ? true : false);

  useEffect(() => {
    if (window.history && window.history.pushState) {
      window.addEventListener("popstate", function () {
        //remove last item from locations
        setLocations((loc) => loc.slice(0, loc.length - 1));
      });
    }
  }, []);

  useGetOne(
    resource,
    { id: id },
    {
      enabled: resource && id ? true : false,
      onSuccess: (data) => {
        if (data) {
          data.resource = resource;

          if (location.state && location.state.clear) {
            setLocations([data]);
          } else {
            const exists = locations.some((loc) => {
              return parseInt(loc.id) === parseInt(id);
            });

            if (!exists) {
              setLocations([...locations, data]);
            }
          }
        }
      },
    }
  );

  return (
    <RaLayout appBar={AppBar} menu={Menu}>
      <Breadcrumbs
        sx={{ marginTop: 1.5 }}
        className={classes.bread}
        aria-label="breadcrumb"
      >
        <LinkRouter
          to={{ pathname: "/", state: { clear: true } }}
          underline="hover"
          color="inherit"
        >
          <HomeRounded style={{ fontSize: 20 }} />
        </LinkRouter>
        {locations.length > 0 &&
          locations.map((value, index) => {
            console.log("locations", locations);
            const last = index === locations.length - 1;
            const to = `/${value.resource}/${value.id}`;

            return last ? (
              <Typography color="textPrimary" key={to}>
                {value.name}
              </Typography>
            ) : (
              <LinkRouter
                onClick={(d) => {
                  setLocations(
                    locations.slice(
                      0,
                      parseInt(d.target.getAttribute("customindex"))
                    )
                  );
                }}
                underline="hover"
                color="inherit"
                customIndex={index}
                to={to}
                key={to}
              >
                {value.name}
              </LinkRouter>
            );
          })}
      </Breadcrumbs>
      {children}
    </RaLayout>
  );
};

export default Layout;
