import polyglotI18nProvider from "ra-i18n-polyglot";
import pt from "ra-language-portuguese-pt";
import en from "ra-language-english";

import ptLang from "./data/pt.json";
import enLang from "./data/en.json";

const englishMessages = {
  ...en,
  ...enLang,
};
const portugueseMessages = {
  ...pt,
  ...ptLang,
};

console.log(portugueseMessages);

const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === "pt" ? portugueseMessages : englishMessages),
  "pt" // Default locale
);

export default i18nProvider;
