import * as React from "react";
import {
  Resource,
  fetchUtils,
  AdminContext,
  AdminUI,
  CustomRoutes,
} from "react-admin";

import { Route } from "react-router-dom";
import simpleRestProvider from "./helpers/ra-strapi-rest";

import Cookies from "./helpers/Cookies.js";
import authProvider from "./authProvider";

// Resources

import { AppCreate, AppEdit } from "./topics/App.js";
import { ItemEdit, ItemCreate } from "./topics/Item";
import Dashboard from "./Dashboard"; // Dashboard se for precisar nos designs. É só por o parametro na prop
import Menu from "./Menu"; // Items do Menu. Componente criado para poder customizá-los

// Icons

import {
  AppsRounded as AppsIcon,
  LabelRounded as LabelIcon,
} from "@mui/icons-material";

import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./Layout";
import Login from "./Login";
import i18nProvider from "./i18npolyprovider";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = Cookies.getCookie("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const uploadFields = ["screensaver", "image", "video", "audio"];

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL,
  httpClient,
  uploadFields
);

export const App = () => {
  return (
    <AdminContext
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
    >
      <AsyncApps />
    </AdminContext>
  );
};

const AsyncApps = () => {
  return (
    <AdminUI
      layout={Layout}
      loginPage={Login}
      menu={Menu}
      id="teste"
      dashboard={Dashboard}
    >
      <Resource name="items" />
      <Resource name="apps" />
      <CustomRoutes>
        <Route path="/apps/create" element={<AppCreate />} />
        <Route path="/apps/:appId" element={<AppEdit />} />
        <Route path="/apps/:appId/create" element={<ItemCreate />} />
        <Route path="/items/:itemId/create" element={<ItemCreate />} />
        <Route path="/items/:itemId" element={<ItemEdit />} />
      </CustomRoutes>
    </AdminUI>
  );
};

export default App;
