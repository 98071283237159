import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import { Loading } from "./topics/Common";
export default () => null;
// <Card>
//     <Title title="Bem vindo ao CMS" />
//     <CardContent>Selecione uma das aplicações no Menu à esquerda para começar a personalizá-las!</CardContent>
// </Card>
