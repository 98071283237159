import React from "react";
import { useEffect, useState } from "react";

import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  Create,
  SelectInput,
  ReferenceArrayInput,
  useNotify,
  useRefresh,
  useRedirect,
  AutocompleteArrayInput,
  FileInput,
  useRecordContext,
  usePermissions,
  useTranslate,
} from "react-admin";

import { useParams } from "react-router";

import { CustomList, Loading, SaveToolbar, useIsAdmin } from "./Common";
import globalVariables from "../helpers/Global";

import { Card, CardContent, Typography } from "@mui/material";

export const apiUrl = process.env.REACT_APP_API_URL;

const AppTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ""}</span>;
};

const CustomFileField = ({ type, fileSrc, fileTitle }) => {
  // type == "video" || "audio"

  const record = useRecordContext();

  if (!record[type] && !fileSrc) return null;

  return (
    <a target="_blank" href={fileSrc || apiUrl + record[type].url}>
      {fileTitle || record[type].name}
    </a>
  );
};

export const AppEdit = () => {
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoTitle, setVideoTitle] = useState(false);

  const notify = useNotify();

  // Obter o id escolhido através do react router

  const { appId } = useParams();

  // Quando o id for alterado, reiniciar os valores do videoSrc e videoTitle

  useEffect(() => {
    setVideoSrc(null);
    setVideoTitle(false);
  }, [appId]);

  const { isLoading, loaded, permissions } = usePermissions();
  const isAdmin = useIsAdmin();
  const refresh = useRefresh();

  const translate = useTranslate();

  const handleVideo = (files) => {
    let file = files[0];
    setVideoTitle(file.name);
    setVideoSrc(URL.createObjectURL(file));
  };

  const onSuccess = () => {
    notify("common.changes_saved");
    refresh();
  };

  if (isLoading) return <Loading />;

  return (
    <Edit
      id={appId}
      resource="apps"
      title={<AppTitle />}
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess: onSuccess }}
    >
      <SimpleForm toolbar={<SaveToolbar />}>
        <TextInput fullWidth source="name" label={"fields.app.name"} />
        <Card style={{ width: "100%" }} source="items" variant="outlined">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Items
            </Typography>
            <CustomList />
          </CardContent>
        </Card>

        {isAdmin ? (
          <Card style={{ marginTop: 20 }} variant="outlined">
            <CardContent sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Admin
              </Typography>
              <TextInput
                source="credits"
                multiline
                fullWidth
                label={"fields.app.credits"}
              />
              <FileInput
                source="screensaver"
                accept="video/*"
                options={{ onDrop: handleVideo }}
                labelSingle={"common.drop"}
              >
                <CustomFileField
                  type="screensaver"
                  fileTitle={videoTitle}
                  fileSrc={videoSrc}
                />
              </FileInput>
              <TextInput source="type" label={"fields.app.type"} />
              <TextInput source="slug" label={"fields.app.slug"} />
              <SelectInput
                source="language"
                label={"fields.app.language"}
                choices={[
                  { id: "PT", name: "PT" },
                  { id: "EN", name: "EN" },
                ]}
              />
              <NumberInput source="appId" label={"fields.app.appId"} />

              <TextInput
                source="metadata"
                multiline
                fullWidth
                label={"fields.app.metadata"}
              />
            </CardContent>
          </Card>
        ) : null}
      </SimpleForm>
    </Edit>
  );
};

export const AppCreate = (props) => {
  const permissions = usePermissions();
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoTitle, setVideoTitle] = useState(false);

  const translate = useTranslate();

  if (permissions.isLoading) return <Loading />;

  const handleVideo = (files) => {
    let file = files[0];
    setVideoTitle(file.name);
    setVideoSrc(URL.createObjectURL(file));
  };

  return (
    <Create resource="apps">
      <SimpleForm>
        <TextInput source="name" label={"fields.app.name"} />
        <TextInput source="type" label={"fields.app.type"} />
        <TextInput source="slug" label={"fields.app.slug"} />
        <SelectInput
          source="language"
          label={"fields.app.language"}
          choices={[
            { id: "PT", name: "PT" },
            { id: "EN", name: "EN" },
          ]}
        />
        <NumberInput source="appId" label={"fields.app.appId"} />
        <TextInput
          source="credits"
          multiline
          fullWidth
          label={"fields.app.credits"}
        />
        <TextInput
          source="metadata"
          multiline
          fullWidth
          label={"fields.app.metadata"}
        />

        <FileInput
          source="screensaver"
          accept="video/*"
          options={{ onDrop: handleVideo }}
          labelSingle={"common.drop"}
        >
          <CustomFileField
            type="screensaver"
            fileTitle={videoTitle}
            fileSrc={videoSrc}
          />
        </FileInput>

        <ReferenceArrayInput
          reference="items"
          source="items"
          label={"fields.app.items"}
          sort={{ field: "name", order: "ASC" }}
          perPage={10000}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
