import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import Compressor from "compressorjs";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  EditButton,
  useNotify,
  useRefresh,
  useRedirect,
  FileInput,
  ImageInput,
  useRecordContext,
  SimpleList,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
  usePermissions,
  Toolbar,
  SaveButton,
  CreateButton,
  CloneButton,
  TopToolbar,
  useListContext,
  useList,
  useGetOne,
  useTranslate,
} from "react-admin";

import {
  Button as MaterialButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import "../Common.css";
import { useMediaQuery } from "@mui/material";
import {
  RemoveCircleOutline,
  ExpandMoreOutlined,
  ExpandLessOutlined,
} from "@mui/icons-material";

import { useParams, useNavigate, useLocation } from "react-router";

import {
  CustomImageField,
  CustomFileField,
  Loading,
  makeId,
  ItemActions,
  ItemTitle,
  SaveToolbar,
  CustomList,
  useIsAdmin,
} from "./Common.js";
import { LazyResult } from "postcss";

const getValidFields = (metadata) => {
  let validFields;
  try {
    validFields = JSON.parse(metadata).permissions;
  } catch (e) {}
  return validFields;
};

const CustomTextInput = (
  {
    isAdmin = false,
    value,
    multiline = false,
    fullWidth = false,
    defaultValue = false,
  },
  props
) => {
  const record = useRecordContext();

  console.log("value", value);

  const validFields = record ? getValidFields(record.metadata) : undefined; // se der undefined, é porque o user pode editar todos os campos (default value)
  if (isAdmin || !validFields || validFields.includes(value)) {
    return (
      <TextInput
        id={makeId(4)}
        source={value}
        multiline={multiline}
        defaultValue={defaultValue || ""}
        fullWidth={fullWidth}
        label={`fields.item.${value}`}
        record={record}
      />
    );
  }
  return null;
};

const CustomSelectInput = ({ isAdmin = false }, props) => {
  const record = useRecordContext(props);
  const translate = useTranslate();

  const choices = [
    { id: "normal", name: translate("fields.item.normal") },
    { id: "accessibility", name: translate("fields.item.accessibility") },
  ];

  const validFields = record ? getValidFields(record.metadata) : undefined; // se der undefined, é porque o user pode editar todos os campos (default value)
  if (isAdmin || !validFields || validFields.includes("mode")) {
    return (
      <SelectInput
        id={makeId(4)}
        source="mode"
        label={"fields.item.mode"}
        choices={choices}
        defaultValue={"normal"}
      />
    );
  }
  return null;
};

const CustomImageInput = (
  { isAdmin = false, handleImg, imgSrc, imgTitle },
  props
) => {
  const record = useRecordContext(props);

  const classes = makeStyles({
    ImageInput: {
      display: "none",
    },
  });

  const validFields = record ? getValidFields(record.metadata) : undefined; // se der undefined, é porque o user pode editar todos os campos (default value)
  if (isAdmin || !validFields || validFields.includes("image")) {
    return (
      <ImageInput
        id={makeId(4)}
        label={"fields.item.image"}
        className={classes.ImageInput}
        source="image"
        accept="image/*"
        sx={
          !isAdmin && {
            "& .RaFileInputPreview-removeButton": {
              display: "none",
            },
          }
        }
        options={{ onDrop: handleImg }}
        labelSingle={"common.drop"}
      >
        <CustomImageField size="thumbnail" imgSrc={imgSrc} title={imgTitle} />
      </ImageInput>
    );
  }
  return null;
};

const CustomFileInput = (
  {
    isAdmin = false,
    type,
    handleVideo,
    handleAudio,
    videoTitle,
    audioTitle,
    videoSrc,
    audioSrc,
  },
  props
) => {
  const record = useRecordContext(props);

  const validFields = record ? getValidFields(record.metadata) : undefined; // se der undefined, é porque o user pode editar todos os campos (default value)
  if (isAdmin || !validFields || validFields.includes(type)) {
    return (
      <FileInput
        id={makeId(4)}
        label={`fields.item.${type}`}
        source={type}
        sx={
          !isAdmin && {
            "& .RaFileInputPreview-removeButton": {
              display: "none",
            },
          }
        }
        accept={type === "video" ? "video/*" : "audio/*"}
        options={{ onDrop: type === "video" ? handleVideo : handleAudio }}
        labelSingle={"common.drop"}
      >
        <CustomFileField
          type={type}
          fileTitle={type === "video" ? videoTitle : audioTitle}
          fileSrc={type === "video" ? videoSrc : audioSrc}
        />
      </FileInput>
    );
  }
  return null;
};

export const ItemEdit = () => {
  // States for updating the Image / Video and their names

  const [imgSrc, setImgSrc] = useState(null);
  const [imgTitle, setImgTitle] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoTitle, setVideoTitle] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioTitle, setAudioTitle] = useState(null);
  const [saving, setSaving] = useState(false);

  // States for Bootstrap Modal for Image Crop

  const [modalShown, setModalShown] = useState(false);
  const [modalImg, setModalImg] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    height: 100,
  });

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const handleImg = (files) => {
    let file = files[0];
    let reader = new FileReader();
    setImgTitle(file.name);
    reader.onload = (evt) => {
      // Crop Magic Here...
      // reiniciar a posição do crop
      setCompletedCrop(null);
      setCrop({
        unit: "%",
        width: 100,
        height: 100,
      });
      setModalShown(true);
      setModalImg(evt.target.result);
    };

    reader.readAsDataURL(file);
  };

  const handleVideo = (files) => {
    let file = files[0];
    setVideoTitle(file.name);
    setVideoSrc(URL.createObjectURL(file));
  };

  const handleAudio = (files) => {
    let file = files[0];
    setAudioTitle(file.name);
    setAudioSrc(URL.createObjectURL(file));
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const transform = async (data) => {
    const canvas = previewCanvasRef.current;

    // Inserir o novo tipo do item automaticamente
    let newData = { ...data };

    if (canvas.style.width == "0px") {
      // não houve upload. Usar a imagem original
      return newData;
    }

    let imgTransform = () =>
      new Promise(function (resolve, reject) {
        canvas.toBlob((blob) => {
          new Compressor(blob, {
            quality: 0.6,
            maxWidth: 1920,
            success(result) {
              resolve(result);
            },
            error(err) {
              console.log(err.message);
            },
          });
        }, "image/webp");
      });

    if (newData.image) {
      const blobResult = await imgTransform();

      newData.image.blobFile = blobResult;
      newData.image.blobFile.name = imgTitle;
    }

    return newData;
  };

  const modalSave = () => {
    setSaving(true);

    const image = document.getElementsByClassName("ReactCrop__image")[0];

    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    setImgSrc(canvas.toDataURL());
    setModalShown(false);
    setSaving(false);
  };

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { appId, itemId } = useParams();

  const permissions = usePermissions();
  const isAdmin = useIsAdmin();

  if (permissions.Loading) return <Loading />;

  // console.log("edit permissions", permissions)

  const onSuccess = () => {
    notify("common.changes_saved");
    setImgSrc(null);
    refresh();
  };
  return (
    <Edit
      id={itemId}
      resource="items"
      title={<ItemTitle />}
      transform={transform}
      mutationOptions={{
        onSuccess: onSuccess,
      }}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={<SaveToolbar extraActions undefImg={imgSrc == undefined} />}
      >
        <CustomTextInput value="name" />

        <CustomTextInput value="text" multiline fullWidth />
        <CustomImageInput
          isAdmin={isAdmin}
          handleImg={handleImg}
          imgSrc={imgSrc}
          imgTitle={imgTitle}
        />
        <CustomFileInput
          isAdmin={isAdmin}
          handleVideo={handleVideo}
          videoSrc={videoSrc}
          videoTitle={videoTitle}
          type="video"
        />
        <CustomFileInput
          isAdmin={isAdmin}
          handleAudio={handleAudio}
          audioSrc={audioSrc}
          audioTitle={audioTitle}
          type="audio"
        />

        <Card style={{ width: "100%" }} source="items" variant="outlined">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Items
            </Typography>
            <CustomList />
          </CardContent>
        </Card>

        <Modal
          show={modalShown}
          onHide={() => setModalShown(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Selecione a área da foto (16:9)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactCrop
              src={modalImg}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              onload={onLoad}
            />
          </Modal.Body>
          <Modal.Footer>
            {saving ? (
              <Button disabled>A guardar...</Button>
            ) : (
              <Button
                id="modalSaveButton"
                variant="primary"
                onClick={modalSave}
              >
                Guardar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <div>
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
              display: "none",
            }}
          />
        </div>

        {isAdmin ? (
          <Card style={{ marginTop: 20 }} variant="outlined">
            <CardContent
              fullWidth
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Admin
              </Typography>
              <CustomTextInput value="type" />
              <CustomTextInput value="slug" />
              <CustomSelectInput />
              <CustomTextInput value="metadata" multiline />
            </CardContent>
          </Card>
        ) : null}
      </SimpleForm>
    </Edit>
  );
};

export const ItemCreate = () => {
  const location = useLocation();
  const { appId, itemId } = useParams();

  const notify = useNotify();
  const navigate = useNavigate();
  const redirect = useRedirect();

  const onSuccess = (dat) => {
    notify("fields.item.created");
    setImgSrc(null);
    navigate(-1);
  };

  // States for Bootstrap Modal for Image Crop

  const [imgSrc, setImgSrc] = useState(null);
  const [imgTitle, setImgTitle] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoTitle, setVideoTitle] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioTitle, setAudioTitle] = useState(null);
  const [saving, setSaving] = useState(false);

  const [modalShown, setModalShown] = useState(false);
  const [modalImg, setModalImg] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    height: 100,
  });

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const handleImg = (files) => {
    let file = files[0];
    let reader = new FileReader();
    setImgTitle(file.name);

    reader.onload = (evt) => {
      // Crop Magic Here...
      // reiniciar a posição do crop
      setCompletedCrop(null);
      setCrop({
        unit: "%",
        width: 100,
        height: 100,
      });
      setModalShown(true);
      setModalImg(evt.target.result);
    };

    reader.readAsDataURL(file);
  };

  const modalSave = () => {
    setSaving(true);

    const image = document.getElementsByClassName("ReactCrop__image")[0];

    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    setImgSrc(canvas.toDataURL());
    setModalShown(false);
    setSaving(false);
  };

  useEffect(() => {
    let saveButton = document.getElementById("modalSaveButton");
    if (completedCrop && completedCrop.width == 0 && saveButton) {
      saveButton.disabled = true;
    } else if (saveButton) {
      saveButton.disabled = false;
    }
  }, [completedCrop]);

  const { isLoading } = usePermissions();
  const isAdmin = useIsAdmin();

  const selectedApp = useGetOne(
    "apps",
    { id: appId },
    { enabled: appId ? true : false }
  );

  const [selType, setSelType] = useState(0);

  const typeRef = useRef(null);

  if (selectedApp.isLoading || isLoading) return <Loading />;

  let appMetadata = selectedApp.data ? selectedApp.data.metadata : undefined;
  let itemCreationData = []; // Guarda o conteúdo de cada tipo de item que pode ser criado
  try {
    if (appMetadata) {
      itemCreationData = JSON.parse(appMetadata).itemCreation;
    }
  } catch (e) {}

  let selFields;
  if (itemCreationData) {
    selFields = itemCreationData[selType];
  }

  const handleVideo = (files) => {
    let file = files[0];
    setVideoTitle(file.name);
    setVideoSrc(URL.createObjectURL(file));
  };

  const handleAudio = (files) => {
    let file = files[0];
    setAudioTitle(file.name);
    setAudioSrc(URL.createObjectURL(file));
  };

  const upload = async (data) => {
    const canvas = previewCanvasRef.current;

    // Inserir o novo tipo do item automaticamente
    let newData = { ...data };

    if (canvas.style.width == "0px") {
      // não houve upload. Usar a imagem original
      return newData;
    }

    let imgTransform = () =>
      new Promise(function (resolve, reject) {
        canvas.toBlob((blob) => {
          new Compressor(blob, {
            quality: 0.6,
            maxWidth: 1920,
            success(result) {
              resolve(result);
            },
            error(err) {
              console.log(err.message);
            },
          });
        }, "image/webp");
      });

    if (newData.image) {
      const blobResult = await imgTransform();

      newData.image.blobFile = blobResult;
      newData.image.blobFile.name = imgTitle;
    }

    return newData;
  };

  return (
    <Create
      resource="items"
      id={itemId}
      title={<ItemTitle />}
      mutationOptions={{ onSuccess: onSuccess }}
      transform={upload}
      undoable={false}
    >
      <SimpleForm toolbar={<SaveToolbar undefImg={imgSrc == undefined} />}>
        <CustomTextInput value="name" />

        <CustomTextInput value="text" multiline fullWidth />
        <CustomImageInput
          isAdmin={isAdmin}
          handleImg={handleImg}
          imgSrc={imgSrc}
          imgTitle={imgTitle}
        />
        <CustomFileInput
          handleVideo={handleVideo}
          videoSrc={videoSrc}
          videoTitle={videoTitle}
          type="video"
        />
        <CustomFileInput
          handleAudio={handleAudio}
          audioSrc={audioSrc}
          audioTitle={audioTitle}
          type="audio"
        />

        <Modal
          show={modalShown}
          onHide={() => setModalShown(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Selecione a área da foto (16:9)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactCrop
              src={modalImg}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              onload={onLoad}
            />
          </Modal.Body>
          <Modal.Footer>
            {saving ? (
              <Button disabled>A guardar...</Button>
            ) : (
              <Button
                id="modalSaveButton"
                variant="primary"
                onClick={modalSave}
              >
                Guardar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <div>
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
              display: "none",
            }}
          />
        </div>

        {isAdmin ? (
          <Card style={{ marginTop: 20 }} variant="outlined">
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Admin
              </Typography>
              <CustomTextInput value="type" defaultValue="item" />
              <CustomTextInput value="slug" />
              <NumberInput
                source={itemId ? "parent" : "app"}
                defaultValue={itemId ? itemId : appId}
                label={itemId ? "fields.item.parent" : "App"}
                hidden={false}
              />
              <CustomSelectInput />
              <CustomTextInput value="metadata" multiline />
            </CardContent>
          </Card>
        ) : null}
      </SimpleForm>
    </Create>
  );
};
