// in src/Menu.js
import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { createElement } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import {
  MenuItemLink,
  getResources,
  useRefresh,
  useSidebarState,
  usePermissions,
  useGetList,
  useTranslate,
  useLocaleState,
  CreateButton,
} from "react-admin";
import LabelIcon from "@mui/icons-material/Label";
import AddIcon from "@mui/icons-material/Add";
import AppsIcon from "@mui/icons-material/Apps";

import globalVariables from "./helpers/Global";

import { useLocation, useNavigate } from "react-router-dom";

import "./Common.css";
import { Button } from "@mui/material";
import { Loading, useIsAdmin } from "./topics/Common";

const Menu = ({ logout }) => {
  const location = useLocation();

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const open = useSidebarState();

  const [apps, setApps] = useState([]);

  const translate = useTranslate();

  const [locale, setLocale] = useLocaleState();

  useGetList(
    "apps",
    {
      filter: {
        language: translate("common.languageName"),
      },
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    },
    {
      onSuccess: (as) => {
        if (!as) return;
        setApps(as.data);
      },
    }
  );

  const refresh = useRefresh();
  const navigate = useNavigate();

  const handleLangChange = (e) => {
    // Quando muda-se a lingua, não existe app selecionado, e é necessário carregar os apps da lingua escolhida
    setLocale(e.target.value);
    navigate(`/`);
  };

  const permissions = usePermissions();

  const isAdmin = useIsAdmin();

  if (permissions.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <FormControl
        variant="filled"
        style={{
          width: "85%",
          marginTop: "1em",
          marginLeft: "16px",
          marginBottom: "0.5em",
          zIndex: "1",
        }}
      >
        <InputLabel htmlFor="filled-age-native-simple">
          {translate("common.languageLabel")}
        </InputLabel>
        <Select
          id="select-language-input"
          value={translate("common.languageName").toLowerCase()}
          onChange={(e) => {
            handleLangChange(e);
          }}
          inputProps={{
            name: "language",
            id: "filled-language-native-simple",
          }}
        >
          <MenuItem key="pt" value={"pt"}>
            Português
          </MenuItem>
          <MenuItem key="en" value={"en"}>
            English
          </MenuItem>
        </Select>
      </FormControl>
      {isAdmin && (
        <CreateButton
          to={{
            pathname: `/apps/create`,
            state: {
              clear: true,
            },
          }}
          label={"fields.app.create"}
        />
      )}

      {apps.map((app, idx) => {
        return (
          <div onClick={() => refresh(true)}>
            <MenuItemLink
              key={app.name}
              state={{ clear: true }}
              to={{ pathname: `/apps/${app.id}` }}
              primaryText={
                app.name.substring(0, 1).toUpperCase() + app.name.substring(1)
              }
              leftIcon={createElement(AppsIcon)}
              className="menuItem"
              style={{
                marginBottom: "0.5em",
                marginTop: idx == 1 ? "0.5em" : "0",
              }}
            />
          </div>
        );
      })}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
