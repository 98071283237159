import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import logo from "./assets/logo.png";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Input,
  TextField,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ArrowForward } from "@mui/icons-material";
import { Notification, useTranslate, useLogin, useNotify } from "react-admin";

import { lightTheme } from "./themes";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "black",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    maxWidth: 300,
    marginTop: "6em",
    backgroundColor: "transparent",
    border: "none",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    background: "transparent",

    justifyContent: "center",
  },
  logo: {
    width: "100%",
    height: "100%",
    padding: 5,
    marginBottom: 40,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  inputTop: {
    backgroundColor: "white",
    marginBottom: "0 !important",
    borderBottom: "0 !important",
    borderBottomLeftRadius: "0 !important",
    borderBottomRightRadius: 0,
    borderRadius: "3px",
    padding: "11px 10px 9px",
  },
  inputBottom: {
    backgroundColor: "white",
    marginTop: "0 !important",
    borderTop: "0 !important",
    borderTopLeftRadius: "0 !important",
    borderTopRightRadius: 0,
    borderRadius: "3px",
    padding: "11px 10px 9px",
  },
  button: {
    backgroundColor: "black",
    borderColor: "white",
    color: "white",
    height: 50,
    borderRadius: "50px",
    fontWeight: "bold",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const notify = useNotify();
  const login = useLogin();

  const classes = useStyles();

  const handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    // will call authProvider.login({ email, password })
    login({ username, password }).catch(() =>
      notify("User ou password inválidos")
    );
  };

  const translate = useTranslate();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <img alt="logo" className={classes.logo} src={logo} />
          </div>
          <div className={classes.form}>
            <div className={classes.inputTop}>
              <Input
                name="username"
                type="username"
                autoFocus
                fullWidth
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={translate("common.username")}
              />
            </div>
            <div className={classes.inputBottom}>
              <Input
                name="password"
                type="password"
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={translate("common.password")}
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              variant="outlined"
              name="submit"
              type="submit"
              className={classes.button}
              fullWidth
              endIcon={
                <ArrowForward
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 12,
                    fontSize: 24,
                  }}
                />
              }
            >
              {translate("common.login")}
            </Button>
          </CardActions>
        </Card>
        <Notification />
      </div>
    </form>
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={createTheme(adaptV4Theme(lightTheme))}>
      <Login />
    </ThemeProvider>
  </StyledEngineProvider>
);

export default LoginWithTheme;
