import React, { useEffect } from "react";

import "./Common.css";
import LoadGif from "../assets/gifs/loading-buffering.gif";
import {
  TopToolbar,
  useRecordContext,
  CreateButton,
  SaveButton,
  Toolbar,
  Datagrid,
  List,
  TextField,
  usePermissions,
  useListParams,
  Labeled,
  DeleteButton,
  CloneButton,
  Pagination,
  useListContext,
  useTranslate,
  useCreatePath,
  Link,
  useRefresh,
} from "react-admin";

import { useLocation, useParams } from "react-router";

import { apiUrl } from "./App";
import { Box, Typography, Card, CardContent, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";

import { useNavigate } from "react-router";
import { Add, LocationCityOutlined } from "@mui/icons-material";
import { useState } from "react";

export const Loading = () => (
  <div className="loading-flex">
    <img className="loading-gif" src={LoadGif} alt="loading"></img>
  </div>
);

export const getItemsById = (idArray) => {
  // String que indica os ids do item
  let ret = "";
  idArray.map((item, idx) => {
    let _item = typeof item === "string" ? item : item.id;
    if (idx == 0) {
      ret += _item;
    } else {
      ret += "&id=" + _item;
    }
  });

  return ret;
};

export const makeId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const CustomImageField = ({ size, imgSrc, title }) => {
  // Verifica se o file é o do record ou se será o novo file cropped

  const record = useRecordContext();

  if (record && !record.image && !imgSrc) return null;

  const src = imgSrc || (record && record.image.url);
  const name = title || (record && record.image.name);
  let width = record && record.image ? record.image.formats : "";

  if (src == null || name == null) {
    return null;
  }
  if (!width) {
    // Se a imagem for de apenas um tamanho, o record tem o campo dos formats a 'null'
    width = 245;
  } else {
    width = record && record.image ? width[size].width : 245;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <img width={width + "px"} src={imgSrc || apiUrl + src} alt={name} />
    </div>
  );
};

export const CustomFileField = ({ type, fileSrc, fileTitle }) => {
  // Mesma lógica do CustomImageField, mas para files

  // type == "video" || "audio"

  const record = useRecordContext();
  if (record && !record[type] && !fileSrc) return null;

  let href = fileSrc ? fileSrc : record && apiUrl + record[type].url;

  return (
    <a target="_blank" href={href}>
      {fileTitle || record[type].name}
    </a>
  );
};

export const ItemTitle = ({ record }) => {
  return <span>{`${record.name}`}</span>;
};

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  list: {
    marginTop: -55,
  },
  card: {
    width: "100%",
    boxShadow: "0px 0px 1px 0px #000000",
    backgroundColor: "rgba(0, 0, 0, 0.04);",
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const SaveToolbar = ({ undefImg, extraActions = false }) => {
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const translate = useTranslate();

  return (
    <Toolbar classes={useStyles()}>
      <SaveButton label={"common.save"} />

      {isAdmin && extraActions ? <CloneButton label={"common.clone"} /> : null}
      {isAdmin && extraActions ? (
        <DeleteButton
          label={"common.delete"}
          mutationMode="optimistic"
          redirect={(resource, id, data) => {
            return navigate(-1);
          }}
        />
      ) : null}
    </Toolbar>
  );
};

export const ItemActions = ({ selectedApp, isAdmin }) => {
  let endUserCanCreate;
  try {
    let meta = JSON.parse(selectedApp.data.metadata);
    endUserCanCreate = meta.itemCreation;
  } catch (e) {}

  if (isAdmin || endUserCanCreate) {
    return (
      <TopToolbar>
        <CreateButton />
      </TopToolbar>
    );
  }

  return null;
};

const ListActions = ({ isAdmin }) => {
  const { appId, itemId } = useParams();

  const translate = useTranslate();

  const linkTo = appId ? `/apps/${appId}/create` : `/items/${itemId}/create`;

  return (
    <TopToolbar>
      {isAdmin ? (
        <Link to={linkTo}>
          <Add />
          {translate("fields.item.create")}
        </Link>
      ) : (
        <div style={{ padding: 20 }}></div>
      )}
    </TopToolbar>
  );
};

export const CardCustom = ({ children }) => {
  const classes = useStyles();

  return <Card className={classes.card}>{children}</Card>;
};

const PostPagination = () => {
  const classes = useStyles();
  const { page, perPage, total, setPage } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;

  const translate = useTranslate();

  return (
    nbPages > 1 && (
      <Toolbar className={classes.pagination}>
        <Typography>
          {translate("common.rowsPerPage")}: {perPage}
        </Typography>
        <Typography>
          {translate("common.page")}: {page}/{nbPages}
        </Typography>
        {page > 1 && (
          <Button
            style={{ align: "right" }}
            color="primary"
            key="prev"
            onClick={() => setPage(page - 1)}
          >
            Anterior
          </Button>
        )}
        {page !== nbPages && (
          <Button
            style={{ align: "right" }}
            color="primary"
            key="next"
            onClick={() => setPage(page + 1)}
          >
            Seguinte
          </Button>
        )}
      </Toolbar>
    )
  );
};

export const CustomList = () => {
  const record = useRecordContext();

  const classes = useStyles();

  const isAdmin = useIsAdmin();

  return (
    <Box>
      <List
        resource="items"
        empty={false}
        filter={{
          id:
            record.items && record.items.length > 0
              ? getItemsById(record.items)
              : 0,
        }}
        perPage={20}
        total={1000}
        pagination={<PostPagination />}
        actions={<ListActions isAdmin={isAdmin} />}
        bulkActionButtons={false}
        className={classes.list}
        component={CardCustom}
      >
        <Datagrid
          stickyHeader={true}
          ids={record.items}
          bulkActionButtons={false}
          total={1000}
          rowClick={(id, basePath, record) => {
            return `/items/${id}`;
          }}
        >
          <TextField
            sortable={false}
            source="name"
            label={"fields.item.name"}
          />
        </Datagrid>
      </List>
    </Box>
  );
};

export const useIsAdmin = () => {
  const { permissions } = usePermissions();
  return permissions === "Admin";
};
